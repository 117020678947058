<template>
  <div>
    <div class="p-6">
      <el-card>
        <el-tabs>
          <el-tab-pane label="Roles">
            <el-button type="primary" plain @click="handleShowAddRole"
              >Add</el-button
            >
            <el-table :data="roles" class="w-full mt-1" :border="true">
              <el-table-column type="index" width="50" label="#">
              </el-table-column>
              <el-table-column prop="name" label="Name"> </el-table-column>

              <el-table-column label="Permissions">
                <template slot-scope="scope">
                  <div v-if="scope.row.permissions && scope.row.permissions.length >0">
                    <el-tag
                      class="m-2"
                      v-for="(item, index) in scope.row.permissions"
                      :key="index"
                      >{{ item.name }}</el-tag
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Actions" width="100">
                <template slot-scope="scope">
                  <div class="text-center">           
                    <feather-icon icon="EditIcon"
                      class="rolepermissionicon"
                      v-show="true"
                      title="Sửa"
                      @click="handleEditRole(scope.row)"
                      :style="{
                        color: '#2A6EBB',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }"
                    />
                    <feather-icon icon="TrashIcon"
                      class="rolepermissionicon"
                      v-show="true"
                      title="Xóa"
                      :style="{
                        color: '#2A6EBB',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }"
                      @click="handleDeleteRole(scope.row)"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Permissions">
            <div>
              <el-table :data="permissions" class="w-full mt-6" :border="true">
                <el-table-column type="index" width="50" label="#">
                </el-table-column>
                <el-table-column prop="name" label="Name"> </el-table-column>

                <el-table-column prop="displayName" label="Display Name">
                </el-table-column>
                <el-table-column label="Actions" width="100">
                  <template slot-scope="scope">
                    <div class="text-center">
                      <feather-icon icon="EditIcon"
                        class="rolepermissionicon"
                      v-show="true"
                        title="Sửa"
                        @click="handleEditPermission(scope.row)"
                        :style="{
                          color: '#2A6EBB',
                          cursor: 'pointer',
                          marginLeft: '10px',
                        }"
                      />
                      <feather-icon icon="TrashIcon"
                      class="rolepermissionicon"
                      v-show="true"
                        title="Xóa"
                        :style="{
                          color: '#2A6EBB',
                          cursor: 'pointer',
                          marginLeft: '10px',
                        }"
                        @click="handleDeletePermission(scope.row)"
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>

    <el-dialog title="Add/Edit" :visible.sync="dialog">
      <el-form>
        <el-form-item label="Role Name">
          <el-input
            v-model="role.name"
            id="roleName"
            type="text"
            required
            placeholder="Role Name"
          />
        </el-form-item>

        <el-form-item label="Display Name">
          <el-input
            v-model="role.displayName"
            id="displayName"
            type="text"
            required
            placeholder="Role Display Name"
          />
        </el-form-item>
        <el-form-item label="Chọn Permissions:">
          <el-checkbox-group v-model="permissionsRoles">
            <el-checkbox
              v-for="item in permissions"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-button class="mt-3" block @click="handleAddUpdateRole"
          >Save
        </el-button>
      </el-form>
    </el-dialog>
    <el-dialog title="Edit Permission" :visible.sync="dialogPermission">
      <el-form>
        <el-form-item label="Permission Name">
          <el-input
            v-model="rowPermission.name"
            id="keyword"
            type="text"
            required
            placeholder="Role Name"
          />
        </el-form-item>

        <el-form-item label="Display Name">
          <el-input
            v-model="rowPermission.displayName"
            id="keyword"
            type="text"
            required
            placeholder="Role Display Name"
          />
        </el-form-item>

        <el-button class="mt-3" block @click="savePermission"
          >Save
        </el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {},
  data() {
    return {
      role: {
        name: "",
        displayName: "",
        id: "",
        permissions: [],
        allPermissions: []
      },
      rowPermission:{
        id: "",
        name : "",
        displayName : "",
      },
      title: "Thêm bài học",
      items: [
        {
          text: "BimSpeed",
          href: "/",
        },
        {
          text: "Admin",
          href: "/admin",
        },
        {
          text: "Quản trị hệ thống",
          href: "/admin/quan-tri-he-thong",
        },
        {
          text: "Quản lý Roles",
          active: true,
        },
      ],
      isAdd: true,
      dialog: false,
      dialogPermission: false,
      id: "",
      name: "Revit",
      permissions: [],
      permissionDropDown: [],
      roles: [],
      permissionsRoles: [],
    };
  },
  methods: {
    async handleDeletePermission(row){
      try{
        let res = await axios.delete(`permissions/${row.id}`);
        if(res.data.succeeded){
           this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: `Delete Permission thành công`,
                variant: "success",
              },
            });
        }
      }catch (e){

      }
        await this.getPermissions();
    },
    handleShowAddRole() {
      this.isAdd = true;
      this.id = null;
      this.dialog = true;
    },
   async savePermission(){
     try{
        let res = await axios.put("permissions", this.rowPermission);
        if(res.data.succeeded){
          this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: `Update Permission thành công`,
                variant: "success",
              },
            });
            await this.getPermissions();
        }
        }catch (e){
                 this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: "Update Permission không thành công",
              variant: "danger",
            },
          });
        }
        this.dialogPermission = false;
    },
  async  handleDeleteRole(row){
      try{
        let res = await axios.delete('roles',{ params: { id : row.id }});
       if(res.data.succeeded){
         this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: `Bạn đã xoá thành công role ${row.name}`,
                variant: "success",
              },
            });
           await this.getRoles();
       }
      }catch(e) {
           this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: "Xoá role không thành công",
              variant: "danger",
            },
          });
      }
    },
    handleEditRole(row) {
      let rowData = Object.assign({}, row)
      this.isAdd = false;
      this.role = rowData;
      this.permissionsRoles = rowData.permissions.map((x) => x.id);
       this.dialog = true;
    },
    async getPermissions() {
      try {
        let res = await axios.get("permissions/all");
        this.permissions = res.data.data.items;
        this.allPermissions = res.data.data.items;
      } catch (e) {}
    },
    async getRoles() {
      try {
        let res = await axios.get("roles/all");
        console.log(res)
        this.roles = res.data.data;
      } catch (e) {}
    },
  async handleEditPermission(row) {
      try {
        let dataRow = { id: row.id, name: row.name, displayName: row.displayName};
        this.rowPermission = dataRow;
        this.dialogPermission = true;    
      }catch (e) {
            console.log(e)
      }
    },
    async handleAddUpdateRole() {
      if (this.isAdd) {
        try{
        this.dialog = false;
        this.role.permissions = this.permissionsRoles;
        await axios.post("roles", this.role);
             await this.getRoles();
         this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: `Bạn đã thêm thành công role ${this.role.name}`,
                variant: "success",
              },
            });
         
        }catch(e){
             this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: "Thêm Role không thành công",
              variant: "danger",
            },
          });
        }
      
      } else {
        try{
        this.dialog = false;
        let allRoleId = this.permissions.map(x=>x.id);
        await axios.put(`roles/${this.role.id}/name`, this.role);
        await axios.put(`roles/${this.role.id}/permissions`, {
          deletePermissionIds : allRoleId,
          permissionIds: this.permissionsRoles,
        });
        this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: `Update role thành công`,
                variant: "success",
              },
            });
        }catch(e){
               this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: "Update Role không thành công",
              variant: "danger",
            },
          });
        }
        await this.getRoles();
      }
    },
  },
  async created() {
    await this.getPermissions();
    await this.getRoles();
  },
};
</script>

<style scoped>
a {
  background-color: transparent;
}
.rolepermissionicon:hover{
 transform: scale(1.5);
}
</style>
